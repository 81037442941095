import { db } from "@flowby/firebase";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../components/shared/Loader";

export default function ConvertManualPage() {
	const navigate = useNavigate();
	const params = useParams();
	const store = params.store as string;
	const queue = params.queue as string;
	const queuerId = params.queuerId as string;

	useEffect(() => {
		db.convertManualQueueNumber(store, queue, queuerId);
		navigate(`/${store}/q/${queue}/${queuerId}`);
	}, []);

	return <Loader />;
}
