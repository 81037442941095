import {
	ChakraProvider,
	extendTheme,
	withDefaultColorScheme,
} from "@chakra-ui/react";
import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/montserrat/900.css";
import "@fontsource/work-sans/400.css";
import "@fontsource/work-sans/700.css";
import "./styles.css";
import { db } from "@flowby/firebase";
import { theme } from "@flowby/general";
import { Outlet, useParams } from "react-router-dom";
import "./i18n";
import { changeLanguage } from "i18next";
import { useEffect } from "react";
import OfflineToast from "./components/shared/OfflineToast";
import { useSessionStore } from "./libs/store";

const chakraTheme = extendTheme(
	theme.styles,
	theme.components,
	theme.colors,
	withDefaultColorScheme({
		colorScheme: "teal",
	}),
);

export default function MyApp() {
	const params = useParams();
	const userLang = useSessionStore((state) => state.userLanguage);
	const resolveLanguage = async () => {
		if (userLang) {
			changeLanguage(userLang);
			return;
		}
		const store = params.store;
		if (store) {
			const storeQuery = await db.getStore(store);
			const storeData = storeQuery.data();
			if (storeData) {
				changeLanguage(storeData.lang);
				return;
			}
		}
	};

	useEffect(() => {
		resolveLanguage();
	}, []);

	return (
		<ChakraProvider theme={chakraTheme}>
			<OfflineToast />
			<Outlet />
		</ChakraProvider>
	);
}
