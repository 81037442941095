import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	Image,
	Stack,
} from "@chakra-ui/react";
import {
	type GroupData,
	type QueueData,
	type QueueNumberData,
	resolveQueueNumberWithPrefix,
} from "@flowby/firebase";
import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Footer from "../shared/Footer";
import Language from "../shared/Language";
import MyTurn from "./MyTurn";
import Notification from "./Notification";
import QueueNumber from "./QueueNumber";
import UnorderedQueue from "./UnorderedQueue";

// this is only used for showing the queue number on the turn passed screen
let savedQueueNumber: number | undefined = undefined;

export default function QueueView({
	store,
	queue,
	queueData,
	queueNumberId,
	queueNumberData,
	queueNumbersBefore,
	groupsData,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
	queueNumberId: string;
	queueNumberData: QueueNumberData | undefined;
	queueNumbersBefore: number;
	groupsData: GroupData[] | undefined;
}) {
	const navigate = useNavigate();
	const [logoError, setLogoError] = useState(false);
	const keepMyTurnOnThanks = queueData.config?.keepMyTurnOnThanksPage || false;

	const navigateToThanksPage = () => {
		const queueNumberParam = savedQueueNumber
			? `queue_number=${resolveQueueNumberWithPrefix(savedQueueNumber, queueData, groupsData)}`
			: "";
		navigate(
			`/${store}/q/${queue}/thanks?${queueNumberParam}&queue_number_id=${queueNumberId}`,
		);
	};

	savedQueueNumber = queueNumberData
		? queueNumberData.queueNumber
		: savedQueueNumber;

	const myTurn = queueData.state.currentNumberID === queueNumberId;
	const notificationsEnabled = Boolean(
		!queueData.config?.notifyWhenTurnsUntilMyTurn ||
			queueData.config.notifyWhenTurnsUntilMyTurn > 0,
	);
	let content = null;
	if (myTurn) {
		savedQueueNumber = queueData.state.currentNumber;
		content = (
			<Stack flex={1}>
				<MyTurn
					queueData={queueData}
					myNumber={resolveQueueNumberWithPrefix(
						savedQueueNumber,
						queueData,
						groupsData,
					)}
					onClose={keepMyTurnOnThanks ? undefined : navigateToThanksPage}
				/>
			</Stack>
		);
	} else if (!queueNumberData) {
		const queueNumberParam = savedQueueNumber
			? `queue_number=${resolveQueueNumberWithPrefix(savedQueueNumber, queueData, groupsData)}`
			: "";
		content = (
			<Navigate
				to={`/${store}/q/${queue}/thanks?${queueNumberParam}&queue_number_id=${queueNumberId}${keepMyTurnOnThanks ? "&keep_my_turn=true" : ""}`}
			/>
		);
	} else {
		if (queueData.config?.queueIsUnordered) {
			content = (
				<UnorderedQueue
					store={store}
					queue={queue}
					queueNumberId={queueNumberId}
					queueNumberData={queueNumberData}
				/>
			);
		} else {
			content = (
				<QueueNumber
					store={store}
					queue={queue}
					queueNumberId={queueNumberId}
					queueData={queueData}
					queueNumberData={queueNumberData}
					queueNumbersBefore={queueNumbersBefore}
					groupsData={groupsData}
				/>
			);
		}
	}
	return (
		<Stack minH="100%" justify="space-between">
			<Grid
				py={2}
				px={2}
				templateRows="repeat(1, 1fr)"
				templateColumns="repeat(4, 1fr)"
				gap={2}
			>
				<GridItem colSpan={1} pt={1} pl={1} zIndex={1500}>
					<Language store={store} />
				</GridItem>
				<GridItem colSpan={2} mb={-2} pt={1}>
					<Box
						position="relative"
						w="100%"
						h="100%"
						textAlign="center"
						alignItems="center"
					>
						{!logoError && (
							<Image
								role="presentation"
								maxH={43}
								margin="auto"
								fit="contain"
								src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === "dev" ? "flowby-io-dev" : "flowby-io"}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
								onError={() => setLogoError(true)}
							/>
						)}
					</Box>
				</GridItem>
				<GridItem colSpan={1}>
					{!myTurn && notificationsEnabled && (
						<Notification
							store={store}
							queue={queue}
							queueData={queueData}
							queueNumberId={queueNumberId}
							queueNumberData={queueNumberData}
							queueNumbersBefore={queueNumbersBefore}
						/>
					)}
				</GridItem>
			</Grid>
			<Flex
				pt={2}
				justifyContent="center"
				alignItems="center"
				textAlign="center"
			>
				<Heading>{queueData.displayName}</Heading>
			</Flex>
			{content}
			<Footer />
		</Stack>
	);
}
