import { db } from "@flowby/firebase";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import GroupView from "../components/Group/GroupView";
import FlowbyError from "../components/shared/Error";
import Loader from "../components/shared/Loader";
import { useCollData, useDocData } from "../libs/firebaseHooks";

export default function Group() {
	const params = useParams();
	const { t } = useTranslation();
	const store = params.store as string;
	const doesNotExistTranslation = t("group-does-not-exist");
	const groupIsEmptyTranslation = t("group-is-empty");
	// TODO: Handle queue missing from route
	const group = params.group as string;
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const kiosk = (queryParams.get("kiosk") as boolean | null) || false;
	const [groupData, groupLoading] = useDocData(db.getGroupRef(store, group), [
		store,
		group,
	]);
	const [queuesData, queuesLoading] = useCollData(db.getQueuesRef(store), [
		store,
	]);

	if (groupLoading || queuesLoading) {
		return <Loader />;
	}

	if (!groupData) {
		return <FlowbyError text={doesNotExistTranslation} />;
	}
	if (!groupData.queues || groupData.queues.length === 0) {
		return <FlowbyError text={groupIsEmptyTranslation} />;
	}
	if (!queuesData) {
		return <FlowbyError />;
	}

	return (
		<GroupView
			store={store}
			groupData={groupData}
			queuesData={queuesData}
			kiosk={kiosk}
		/>
	);
}
