import {
	Box,
	Flex,
	Grid,
	GridItem,
	Heading,
	Image,
	Stack,
} from "@chakra-ui/react";
import type {
	DataFields,
	PiiCustomData,
	QueueData,
	SupportedLangs,
} from "@flowby/firebase";
import i18next from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormikForm from "../shared/FormikForm";
import Language from "../shared/Language";

export default function DataInputBeforeEnteringQueue({
	store,
	queueData,
	customDataFields,
	setInputData,
}: {
	store: string;
	queueData: QueueData;
	customDataFields: DataFields;
	setInputData: (data: PiiCustomData["data"]) => void;
}) {
	const lang = i18next.language as SupportedLangs;
	const { t } = useTranslation();
	const [logoError, setLogoError] = useState(false);

	return (
		<Stack flex={1}>
			<Stack flex={1}>
				<Grid
					py={2}
					px={2}
					templateRows="repeat(1, 1fr)"
					templateColumns="repeat(4, 1fr)"
					gap={2}
				>
					<GridItem colSpan={1} pt={1} pl={1} zIndex={1500}>
						<Language store={store} />
					</GridItem>
					<GridItem colSpan={2} mb={-2} pt={1}>
						<Box
							position="relative"
							w="100%"
							h="100%"
							textAlign="center"
							alignItems="center"
						>
							{!logoError && (
								<Image
									role="presentation"
									maxH={43}
									margin="auto"
									fit="contain"
									src={`https://firebasestorage.googleapis.com/v0/b/${process.env.REACT_APP_ENV === "dev" ? "flowby-io-dev" : "flowby-io"}.appspot.com/o/stores%2F${store}%2Flogo.png?alt=media`}
									onError={() => setLogoError(true)}
								/>
							)}
						</Box>
					</GridItem>
					<GridItem colSpan={1} />
				</Grid>
				<Flex
					pt={2}
					justifyContent="center"
					alignItems="center"
					textAlign="center"
				>
					<Heading>{queueData.displayName}</Heading>
				</Flex>
			</Stack>

			<Flex p={2} justifyContent="center">
				<Stack
					maxW="xl"
					flex={1}
					spacing={4}
					py={6}
					px={2}
					shadow="md"
					borderWidth="1px"
					bg="white"
					borderRadius="0.375rem"
				>
					<Heading size="lg" textAlign="center">
						{t("data-input-title")}
					</Heading>
					<Box w="100%" px={4} data-testid="data-input-container">
						<FormikForm
							fields={Object.keys(customDataFields)
								.sort()
								.map((key) => {
									const field = customDataFields[key][lang];
									return {
										type: "textInput",
										required: customDataFields[key].required,
										id: key,
										label: field.name,
										helperText: field.description,
										placeholder: field.placeholder || "",
										validation: (value: string) => {
											if (
												customDataFields[key].required &&
												(!value || value.length === 0)
											) {
												return t("required") || "Required";
											}
											return undefined;
										},
									};
								})}
							onSubmit={async (values) => {
								setInputData(values);
							}}
							submitButtonText={t("submit") || "Submit"}
							skipButtonText={t("skip") || "Skip"}
						/>
					</Box>
				</Stack>
			</Flex>
		</Stack>
	);
}
