import {
	Button,
	FormControl,
	FormErrorMessage,
	FormHelperText,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Text,
	Textarea,
} from "@chakra-ui/react";
import {
	Field,
	type FieldInputProps,
	Form,
	Formik,
	type FormikHelpers,
	type FormikProps,
} from "formik";
import type { CSSProperties } from "react";

export type FormikFields = {
	type: string; // this is to support other types of form input in the future
	id: string;
	label?: string;
	required?: boolean;
	helperText?: string;
	placeholder?: string;
	initialValue?: string; // we need intial value on all fields, otherwise formik will not show errors on submit
	value?: string; // used to control value from the outside
	validation?: (value: string) => string | undefined;
	onChange?: (
		e: React.ChangeEvent<any>,
		formikProps: FormikProps<{ [key: string]: string }>,
	) => void;
	formControlStyle?: CSSProperties;
	formHelperTextStyle?: CSSProperties;
}[];

export default function FormikForm({
	fields,
	submitButtonText,
	skipButtonText,
	onSubmit,
	inlineSubmitButton = false,
}: {
	fields: FormikFields;
	submitButtonText?: string;
	skipButtonText?: string;
	inlineSubmitButton?: boolean;
	onSubmit?: (values: { [key: string]: any }) => Promise<void>;
}) {
	const initialValues = fields.reduce(
		(acc: { [key: string]: string }, field) => {
			if (field.initialValue) {
				acc[field.id] = field.initialValue;
			} else {
				acc[field.id] = "";
			}
			return acc;
		},
		{},
	);

	const hasRequiredFields = fields
		.map((f) => {
			return f.required;
		})
		.includes(true);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={async (values: any, actions: FormikHelpers<any>) => {
				onSubmit && (await onSubmit(values));
				actions.setSubmitting(false);
			}}
		>
			{(props) => {
				const hasFilledRequiredFields = !fields
					.map((f) => {
						if (f.required) {
							return Boolean(
								props.values[f.id] && props.values[f.id].length > 0,
							);
						}
						return true;
					})
					.includes(false);
				const hasFilledAnyField = fields
					.map((f) => {
						return props.values[f.id] && props.values[f.id].length > 0;
					})
					.includes(true);
				return (
					<Form style={{ width: "100%" }}>
						<Stack w="100%" direction={inlineSubmitButton ? "row" : "column"}>
							{fields.map((f) => (
								<Field key={f.id} name={f.id} validate={f.validation}>
									{({
										field,
										form,
									}: {
										field: FieldInputProps<any>;
										form: FormikProps<any>;
									}) => {
										const rawFormError = form.errors[f.id];
										const formError =
											typeof rawFormError === "string" ||
											rawFormError instanceof String
												? rawFormError
												: undefined;
										return (
											<FormControl
												isRequired={Boolean(f.required)}
												onSelect={() =>
													f.value && props.setFieldValue(f.id, f.value)
												}
												style={{ ...f.formControlStyle }}
												isInvalid={Boolean(
													form.errors[f.id] && form.touched[f.id],
												)}
											>
												{f.label && (
													<FormLabel fontSize="xl">{f.label}</FormLabel>
												)}
												{f.helperText && (
													<FormHelperText
														pb={2}
														fontSize="lg"
														style={f.formHelperTextStyle}
													>
														{f.helperText}
													</FormHelperText>
												)}
												{f.type === "textInput" && (
													<InputGroup>
														<Input
															{...field}
															onChange={(e: React.ChangeEvent<any>) => {
																f.onChange
																	? f.onChange(e, props)
																	: props.handleChange(e);
															}}
															placeholder={f.placeholder || ""}
														/>
														{inlineSubmitButton && (
															<InputRightElement width="4.5rem">
																<Button
																	size="md"
																	colorScheme="teal"
																	isLoading={props.isSubmitting}
																	type="submit"
																>
																	{submitButtonText}
																</Button>
															</InputRightElement>
														)}
													</InputGroup>
												)}
												{f.type === "textArea" && (
													<Textarea
														{...field}
														onChange={(e: React.ChangeEvent<any>) => {
															f.onChange
																? f.onChange(e, props)
																: props.handleChange(e);
														}}
														placeholder={f.placeholder || ""}
													/>
												)}
												{f.type === "label" && f.value && (
													<Text>{f.value}</Text>
												)}
												<FormErrorMessage>{formError}</FormErrorMessage>
											</FormControl>
										);
									}}
								</Field>
							))}
						</Stack>
						{!inlineSubmitButton && onSubmit && (
							<Stack pt={4}>
								<Button
									display={hasRequiredFields ? "none" : "block"}
									disabled={hasFilledAnyField}
									variant="outline"
									colorScheme="teal"
									isLoading={props.isSubmitting}
									type="submit"
									data-testid="skip-button"
								>
									{skipButtonText}
								</Button>
								<Button
									disabled={
										hasRequiredFields
											? !hasFilledRequiredFields
											: !hasFilledAnyField
									}
									colorScheme="teal"
									isLoading={props.isSubmitting}
									type="submit"
									data-testid="submit-button"
								>
									{submitButtonText}
								</Button>
							</Stack>
						)}
					</Form>
				);
			}}
		</Formik>
	);
}
