import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

type SessionStoreState = {
	userLanguage: string;
	setUserLanguage: (val: string) => void;
};

const useSessionStore = create<SessionStoreState>()(
	persist(
		(set) => ({
			userLanguage: "",
			setUserLanguage: (val) => set({ userLanguage: val }),
		}),
		{
			name: "flowby-session",
			storage: createJSONStorage(() => sessionStorage),
		},
	),
);

export { useSessionStore };
