import { Badge } from "@chakra-ui/react";

export default function QueueStatusBadge({
	status,
	size = "xs",
}: { status: string; size?: string }) {
	return (
		<Badge
			fontSize={size}
			ml={1}
			colorScheme={
				status === "open" ? "teal" : status === "closing" ? "yellow" : "red"
			}
		>
			{status}
		</Badge>
	);
}
