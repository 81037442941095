import { useTranslation } from "react-i18next";

export default function Index() {
	const { t } = useTranslation();
	return (
		<div className="m-8">
			<p className="text-2xl mb-4 capitalize-first">{t("welcome")}</p>
			<span className="text-md leading-8">
				{t("get-more-info")}
				<a
					href="https://flowby.io"
					className="bg-flowby-light p-1 ml-1 rounded-sm"
				>
					{" flowby.io"}
				</a>
			</span>
			<div>
			  <button style={{color: "white"}} onClick={() => {throw new Error("Some error!");}}>error tester</button>
			</div>
		</div>
	);
}
