import {
	Box,
	Button,
	Divider,
	SimpleGrid,
	Stack,
	Text,
	useDisclosure,
	useToast,
} from "@chakra-ui/react";
import {
	type GroupData,
	type QueueData,
	type QueueNumberData,
	db,
	resolveQueueNumberWithPrefix,
} from "@flowby/firebase";
import { theme } from "@flowby/general";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ConfirmAlert from "../shared/ConfirmAlert";
import UpdateValueAnimation from "../shared/UpdateValueAnimation";

export default function QueueNumber({
	store,
	queue,
	queueData,
	queueNumberData,
	queueNumberId,
	queueNumbersBefore,
	groupsData,
}: {
	store: string;
	queue: string;
	queueData: QueueData;
	queueNumberData: QueueNumberData;
	queueNumberId: string;
	queueNumbersBefore: number;
	groupsData: GroupData[] | undefined;
}) {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [leaveQueueLoading, setLeaveQueueLoading] = useState(false);
	const [moveBackLoading, setMoveBackLoading] = useState(false);
	const [myNumber, setMyNumber] = useState<number>(0);
	const moveBackDisclosure = useDisclosure();
	const leaveQueueDisclosure = useDisclosure();
	const toast = useToast();

	// Moved forward message
	useEffect(() => {
		if (!queueNumberData.queueNumber) return;
		if (myNumber > queueNumberData.queueNumber) {
			toast({
				position: "top",
				title: t("moved-forward", { newNumber: queueNumberData.queueNumber }),
				status: "info",
				duration: null,
				isClosable: true,
			});
		}
		setMyNumber(queueNumberData.queueNumber);
	}, [queueNumberData]);

	async function handleMoveBackInQueue() {
		setMoveBackLoading(true);
		const response = await db.moveBackInQueue(store, queue, queueNumberId);
		if (response.success) {
			toast({
				position: "top",
				title: t("move-back-success", { newNumber: response.newNumber }),
				status: "success",
				duration: 4000,
			});
			moveBackDisclosure.onClose();
		} else {
			moveBackDisclosure.onClose();
			toast({
				position: "top",
				title: t("move-back-failure"),
				status: "error",
				duration: 4000,
			});
		}
		setMoveBackLoading(false);
	}

	async function handleLeaveQueue() {
		setLeaveQueueLoading(true);
		await db.leaveQueue(store, queue, queueNumberId);
		navigate(
			`/${store}/q/${queue}/thanks?queue_number_id=${queueNumberId}&leaver=true`,
		);
		setLeaveQueueLoading(false);
	}

	const diffMinutes = (dt2: Date, dt1: Date) => {
		const diff = (dt2.getTime() - dt1.getTime()) / 1000 / 60;
		return Math.abs(Math.round(diff));
	};

	function round5(x: number) {
		if (x < 5) return 5;
		return Math.round(x / 5) * 5;
	}

	// The estimated wait time is calculated by taking the number of people before you in the queue
	// the expected value of the person currently being served.
	function estimatedWaitTime(
		numbersBefore: number,
		estimatedThroughput: number,
		currentNumberServed: Date | null,
	) {
		const now = new Date();
		// if we have currentNumberServed, then we can estimate the time it will take to serve the current customer
		// otherwise we assume it will take half the time of the estimatedThroughput
		// if it becomes negative we set it to 0
		const estServedCustomerDone = Math.max(
			currentNumberServed
				? estimatedThroughput - diffMinutes(currentNumberServed, now)
				: estimatedThroughput / 2,
			0,
		);
		return `${numbersBefore === 0 ? "<" : "~"}${round5(estimatedThroughput * numbersBefore + estServedCustomerDone)}`;
	}

	return (
		<Stack flex={1} data-testid="queue-number">
			<ConfirmAlert
				id="move-back-modal"
				disclosure={moveBackDisclosure}
				headerText={t("move-back-question")}
				content={<Text>{t("move-back-instruction")}</Text>}
				noText={t("no")}
				yesText={t("move-back")}
				yesAction={handleMoveBackInQueue}
				yesLoading={moveBackLoading}
			/>
			<ConfirmAlert
				id="leave-queue-modal"
				disclosure={leaveQueueDisclosure}
				headerText={t("leave-queue-question")}
				content={<Text>{t("leave-queue-instruction")}</Text>}
				noText={t("no")}
				yesText={t("leave-queue")}
				yesLoading={leaveQueueLoading}
				yesAction={handleLeaveQueue}
			/>
			<Stack
				textAlign="center"
				alignItems="center"
				flex={1}
				justifyContent="center"
			>
				<Box
					p={4}
					m={2}
					shadow="md"
					borderWidth="1px"
					bg="white"
					borderRadius="0.375rem"
				>
					<Text textTransform="uppercase" fontWeight="bold" fontSize="2xl">
						{t("numbers-ahead")}
					</Text>

					<Box display="inline-flex">
						<UpdateValueAnimation animateOnChange={[queueNumbersBefore]}>
							<Box px={4}>
								<Text
									data-testid="queue-numbers-before"
									fontWeight="extrabold"
									fontSize="4xl"
								>
									{queueNumbersBefore}
								</Text>
							</Box>
						</UpdateValueAnimation>
					</Box>

					{queueData.config?.estimatedThroughput && (
						<Box pb={1} mt={-1}>
							<Text
								fontFamily={theme.styles.fonts.heading}
							>{`${t("wait-time")}: ${estimatedWaitTime(queueNumbersBefore, queueData.config.estimatedThroughput, queueData.state.currentNumberServed ? queueData.state.currentNumberServed.toDate() : null)} min`}</Text>
						</Box>
					)}

					<Divider borderWidth={2} orientation="horizontal" />

					<SimpleGrid columns={2} spacing={0} w="100%">
						<Stack
							borderRightWidth={2}
							borderColor="gray.100"
							p={2}
							pr={3}
							spacing={0}
						>
							<Text textTransform="uppercase" fontWeight="bold">
								{t("your-queue-number")}
							</Text>
							<Text
								fontWeight="extrabold"
								fontSize="3xl"
								data-testid="my-queue-number"
							>
								{resolveQueueNumberWithPrefix(
									queueNumberData.queueNumber,
									queueData,
									groupsData,
								)}
							</Text>
						</Stack>

						<Stack
							borderLeftWidth={2}
							borderColor="gray.100"
							p={2}
							pl={3}
							spacing={0}
						>
							<Text textTransform="uppercase" fontWeight="bold">
								{t("now-serving")}
							</Text>
							<UpdateValueAnimation
								animateOnChange={[queueData.state.currentNumber]}
							>
								<Box px={4}>
									<Text fontWeight="extrabold" fontSize="3xl">
										{queueData.state.currentNumber === -1
											? "-"
											: resolveQueueNumberWithPrefix(
													queueData.state.currentNumber,
													queueData,
													groupsData,
												)}
									</Text>
								</Box>
							</UpdateValueAnimation>
						</Stack>
					</SimpleGrid>
				</Box>
			</Stack>

			<Stack alignItems="center" justifyContent="flex-end" px={2}>
				<Button
					className="plausible-event-name=Queue+Move+Back"
					variant="outline"
					height={20}
					fontSize={26}
					w="100%"
					maxW="xl"
					bgColor="white"
					onClick={moveBackDisclosure.onOpen}
				>
					{t("move-back")}
				</Button>
				<Button
					data-testid="queue-number-leave-queue"
					className="plausible-event-name=Queue+Leave"
					height={20}
					fontSize={26}
					w="100%"
					maxW="xl"
					onClick={leaveQueueDisclosure.onOpen}
				>
					{t("leave-queue")}
				</Button>
			</Stack>
		</Stack>
	);
}
