import { theme } from "@flowby/general";
import { motion, useAnimation } from "framer-motion";
import { useEffect } from "react";

export default function UpdateValueAnimation({
	children,
	animateOnChange,
}: {
	children: JSX.Element;
	animateOnChange: any[];
}) {
	const controls = useAnimation();
	useEffect(() => {
		controls.start({
			scaleY: [1, 1.2, 1],
			scaleX: [1, 1.2, 1],
			backgroundColor: [
				theme.colors.colors.yellow[200],
				theme.colors.colors.yellow[200],
				"#FFFF",
			],
		});
	}, animateOnChange);

	return (
		<motion.div
			animate={controls}
			transition={{ ease: "anticipate", duration: 0.8, delay: 0 }}
			style={{ borderRadius: "2rem", alignSelf: "center" }}
		>
			{children}
		</motion.div>
	);
}
