import { type DataFields, type PiiCustomData, db } from "@flowby/firebase";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
	Navigate,
	useLocation,
	useNavigate,
	useParams,
} from "react-router-dom";
import DataInput from "../components/Queue/DataInput";
import FlowbyError from "../components/shared/Error";
import Loader from "../components/shared/Loader";
import { useDocData } from "../libs/firebaseHooks";

export default function GetNewQueueNumberPage() {
	const { t } = useTranslation();
	const doesNotExistTranslation = t("does-not-exist");
	const params = useParams();
	const location = useLocation();
	const store = params.store as string;
	const queue = params.queue as string;
	const queryParams = new URLSearchParams(location.search);
	const kiosk = (queryParams.get("kiosk") as boolean | null) || false;
	const navigate = useNavigate();
	const [dataInput, setDataInput] = useState<null | PiiCustomData["data"]>(
		null,
	);

	const [queueData, queueDataLoading] = useDocData(
		db.getQueueRef(store, queue),
		[store, queue],
	);

	const [loadingCreateQueueNumber, setLoadingCreateQueueNumber] =
		useState(false);

	const hasCustomDataFields =
		queueData?.customDataFields &&
		Object.keys(queueData.customDataFields).length > 0;

	useEffect(() => {
		if (
			queueData &&
			queueData.state.status === "open" &&
			(!hasCustomDataFields || dataInput)
		) {
			if (!loadingCreateQueueNumber) {
				setLoadingCreateQueueNumber(true);
				db.createQueueNumber(store, queue, false, kiosk).then((queueNumber) => {
					setLoadingCreateQueueNumber(false);
					// if no values set user has skipped the form
					// and we should not store anything as this will count as data input in analytics
					if (
						dataInput &&
						!Object.values(dataInput).every((value) => value === "")
					) {
						db.storeCustomDataInput(queueNumber.id, store, queue, dataInput);
					}
					// redirect to queue page
					navigate(`/${store}/q/${queue}/${queueNumber.id}`);
				});
			}
		}
	}, [queueData, dataInput]);

	// if data loading
	if (queueDataLoading || loadingCreateQueueNumber) {
		return <Loader />;
	}

	// if data not found or error
	if (!queueData) {
		return <FlowbyError text={doesNotExistTranslation} />;
	}

	// if queue closed
	if (
		!queueData.state ||
		queueData.state.status === "closed" ||
		queueData.state.status === "closing"
	) {
		return <Navigate to={`/${store}/q/${queue}/thanks?queue_closed=true`} />;
	}

	// if input required before entering queue
	if (hasCustomDataFields && !dataInput) {
		return (
			<DataInput
				store={store}
				queueData={queueData}
				customDataFields={queueData.customDataFields as DataFields}
				setInputData={setDataInput}
			/>
		);
	}

	return <Loader />;
}
